import {combineReducers} from 'redux';
import {TOGGLE_INFO, MODE} from './reducers-const';

function show(state = false, action) {
  switch (action.type) {
    case `${TOGGLE_INFO}${MODE}`:
      return action.show;
    default:
      return state;
  }
}

export default combineReducers({
  show
});
