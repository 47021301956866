import {LOADING_MODE} from '../utils/helpers';

export function loading(mode) {
  let type;
  switch (mode) {
    case LOADING_MODE.START:
      type = 'LOADING_START';
      break;
    case LOADING_MODE.COMPLETE:
    default:
      type = 'LOADING_COMPLETE';
      break;

  }
  return {
    type
  };
}

export function toggleViewMode(view) {
  return {
    type: 'TOGGLE_VIEW_MODE',
    view
  };
}

export function toggleInfoMode(show) {
  return {
    type: 'TOGGLE_INFO_MODE',
    show
  };
}
