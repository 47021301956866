import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import s from './infoPopUp.scss';
import {getEsi, getMetaSiteId} from '../../utils';

function EditButton({t, template, editorClick, target}) {
  return (
    <a
      data-hooks="card-editor-url"
      className={`${s.editor} ${s.button}`}
      {...target && {target}}
      onClick={() => editorClick(getEsi(template.editorUrl), 'viewer_more', getMetaSiteId(template.editorUrl))}
      href={template.editorUrl}
    >
      {t('template.viewer.info.edit.button')}
    </a>
  );
}

EditButton.propTypes = {
  t: PropTypes.func.isRequired,
  template: PropTypes.object.isRequired,
  editorClick: PropTypes.func.isRequired,
  target: PropTypes.string,
};

function DesktopOnlyNotice({t}) {
  return <div className={s.desktopOnlyNotice} data-hooks="desktop-only-notice">{t('template.viewer.info.desktop.only.notice')}</div>;
}

DesktopOnlyNotice.propTypes = {
  t: PropTypes.func.isRequired
};

function InfoPopUp({t, template, show, toggleInfoMode, editorClick, isMobile, experiments = []}) {
  return (
    <div data-hooks="info-pop-up-component" className={`${s.popupWrapper} ${show ? s.show : ''}`}>
      <div className={s.card}>
        {!isMobile && <button
          data-hooks="card-close"
          className={`${s.closeButton}`}
          onClick={() => toggleInfoMode(!show)}
        >
          <span className={s.hide}>{t('a11y.close.popup.button')}</span>
        </button>}
        <div className={s.content}>
          <h1 data-hooks="card-title" className={s.title}>{t('template.viewer.info.title', {title: template.title})}</h1>
          <div className={s.feature}>
            <h3 data-hooks="card-good-for-title" className={s.subtitle}>{t('template.viewer.info.goodFor')}</h3>
            <p data-hooks="card-good-for" className={s.runningText}>{template.goodFor}</p>
          </div>
          <div className={s.feature}>
            <h3 className={s.subtitle}>{t('template.viewer.info.description')}</h3>
            <p data-hooks="card-description" className={s.runningText}>{template.description}</p>
          </div>
          <p className={`${s.runningText} ${s.feature} ${s.price}`}><span className={s.subtitle}>{t('template.viewer.price')}</span> {template.price}</p>
        </div>
        <div className={s.footer}>
          {!isMobile ? <EditButton target={experiments.includes('TemplateViewerOpenEditorInSameTab') ? '' : '_blank'} t={t} template={template} editorClick={editorClick}/> : <DesktopOnlyNotice t={t}/>}
        </div>
      </div>
    </div>
  );
}

InfoPopUp.propTypes = {
  t: PropTypes.func,
  template: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  toggleInfoMode: PropTypes.func.isRequired,
  editorClick: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  experiments: PropTypes.array,
};

export default translate()(InfoPopUp);
