import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import {VIEW_MODE} from '../../utils/helpers';
import {getEsi, getMetaSiteId} from '../../utils';
import s from './toolBarStyle.scss';
import Logo from '../partials/Logo';
import InfoButton from '../../containers/InfoButton';
import urlParser from 'url';

const defaultSeeAllLink = 'https://www.wix.com/website/templates/html/most-popular';

const Arrow = () => {
  return (
    <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 10.9 19">
      <path d="M1.4 19L0 17.6l8.1-8.1L0 1.4 1.4 0l9.5 9.5"/>
    </svg>
  );
};

export function extractCategories(originUrl) {
  const originUrlParsed = originUrl && urlParser.parse(originUrl);

  const matches = originUrlParsed && /^\/website\/templates\/html\/([^/]+)(?:\/([^/]*[^0-9/][^/]*)?)?(?:\/.*)?$/.exec(originUrlParsed.pathname);
  const categories = (matches && matches.slice(1, 3).filter(notUndefined => notUndefined)) || [];

  return categories;
}

export function getSeeAllTemplatesLink(originUrl) {
  const originUrlParsed = originUrl && urlParser.parse(originUrl);

  const isTempaltesGalleryLink = originUrlParsed && originUrlParsed.host && originUrlParsed.host.endsWith('.wix.com') &&
    originUrlParsed.pathname && originUrlParsed.pathname.startsWith('/website/templates/html/');
  const categories = extractCategories(originUrl);
  const seeAllLink = (isTempaltesGalleryLink && ['https:/', originUrlParsed.host, 'website/templates/html'].concat(categories).join('/')) || defaultSeeAllLink;

  return seeAllLink;
}

class ToolBar extends Component {
  constructor() {
    super();
    this.handleSeeAllTemplaesClick = this.handleSeeAllTemplaesClick.bind(this);
  }

  componentDidMount() {
    window.templateEditUrl = this.props.template.editorUrl;//Adoric need to have this link in the window
  }

  handleSeeAllTemplaesClick(e) {
    const {originUrl = '', seeAllTemplatesClick} = this.props;
    const fromCategory = extractCategories(originUrl).join('--');
    const toCategory = extractCategories(e.target.href).join('--');

    seeAllTemplatesClick && seeAllTemplatesClick({
      fromCategory,
      toCategory,
    });
  }

  render() {
    const {t, view, template, toggleViewMode, editorClick, experiments = [], originUrl = ''} = this.props;
    const seeAllLink = getSeeAllTemplatesLink(originUrl);

    return (
      <div data-hooks="ToolBar-component" className={s.toolBar}>
        <div className={s.elementsGroup}>
          <Logo/>
          <div className={s.elementsGroup}>
            <button
              data-hooks="desktop-view"
              className={`${s.desktopIcon} ${view === VIEW_MODE.DESKTOP ? s.selected : ''}`}
              onClick={() => view === VIEW_MODE.DESKTOP ? '' : toggleViewMode(VIEW_MODE.DESKTOP)}
            ><span className={s.hide}>{t('a11y.desktop.button')}</span></button>
            <hr className={s.hr}/>
            <button
              data-hooks="mobile-view"
              className={`${s.mobileIcon} ${view === VIEW_MODE.MOBILE ? s.selected : ''}`}
              onClick={() => view === VIEW_MODE.MOBILE ? '' : toggleViewMode(VIEW_MODE.MOBILE)}
            ><span className={s.hide}>{t('a11y.mobile.button')}</span></button>
          </div>
        </div>
        <div className={`${s.elementsGroup} ${s.actionMenu}`}>
          <p data-hooks="tool-bar-title" className={s.title}>{t('template.viewer.title')}</p>
          <InfoButton/>
          <a
            className={`${s.button}`}
            data-hooks="editor-link"
            href={template.editorUrl}
            {...experiments.includes('TemplateViewerOpenEditorInSameTab') ? {} : {
              target: '_blank',
            }}
            tabIndex="0"
            onClick={() => editorClick(getEsi(template.editorUrl), 'viewer_top', getMetaSiteId(template.editorUrl))}
          >
            {t('template.viewer.edit.button')}
          </a>
          {experiments.includes('TemplateViewerEnableSeeAllTemplates') ?
            <a
              className={s.seeAllTemplates}
              data-hooks="see-all-templates"
              href={seeAllLink}
              onClick={this.handleSeeAllTemplaesClick}
              tabIndex="0"
            >
              {t('template.viewer.see.all.templates')}
              <Arrow/>
            </a> :
            <label data-hooks="price" className={s.price}>{t('template.viewer.price')} {template.price}</label>
          }
        </div>
      </div>
    );
  }
}

ToolBar.propTypes = {
  t: PropTypes.func,
  view: PropTypes.string.isRequired,
  info: PropTypes.object.isRequired,
  template: PropTypes.object.isRequired,
  toggleViewMode: PropTypes.func.isRequired,
  toggleInfoMode: PropTypes.func.isRequired,
  editorClick: PropTypes.func.isRequired,
  experiments: PropTypes.array,
  originUrl: PropTypes.string,
  seeAllTemplatesClick: PropTypes.func,
};

export default translate()(ToolBar);
