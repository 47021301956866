import React from 'react';
import PropTypes from 'prop-types';
import {VIEW_MODE} from '../../utils/helpers';
import s from './templateDemo.scss';
import DesktopView from '../partials/DesktopView/DesktopView';
import MobileView from '../partials/MobileView/MobileView';

function TemplateDemo({url, view, info}) {
  return (
    <div data-hooks="template-demo-component" className={`${s.demoDisplay} ${info.show ? s.disabled : ''}`}>
      {view === VIEW_MODE.DESKTOP ? <DesktopView url={url}/> : <MobileView url={url}/> }
    </div>
  );
}

TemplateDemo.propTypes = {
  url: PropTypes.string.isRequired,
  view: PropTypes.string.isRequired,
  info: PropTypes.object.isRequired
};

export default TemplateDemo;
