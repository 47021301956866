/* eslint camelcase: "off" */

require('es6-shim');
import biLogger from '@wix/web-bi-logger';

export const bi = biLogger.factory({endpoint: 'spettatore_bi'}).setDefaults({src: 2}).logger();
let options;
export const setBIOptions = data => options = data;

const getLoadingTime = () => {
  if (typeof window !== 'undefined' && 'appStartLoadTime' in window) {
    const endLoadTime = window.performance && window.performance.now();
    return endLoadTime ? endLoadTime - window.appStartLoadTime : 0;
  }

  return 0;
};

export const sendDealerClickBI = targetUrl => {
  const metaSiteIdRegex = /metaSiteId=([\w-]+)/;
  const siteIdRegex = /siteId=([\w-]+)/;
  const esiRegex = /editorSessionId=([\w-]+)/;
  bi.log({
    evid: 15,
    metaSiteId: targetUrl.match(metaSiteIdRegex)[1],
    openingSiteID: targetUrl.match(siteIdRegex)[1],
    loadingesiTime: targetUrl.match(esiRegex)[1],
    origin: 'dealerApi'
  });
};

export const reportLoadSuccess = () => {
  bi.log({
    openingSiteID: options.siteId,
    evid: 43,
    siteType: '2',
    originappurl: options.originUrl,
    loadingTime: Math.round(getLoadingTime())
  });
};

export const reportMobileView = () => {
  bi.log({
    evid: 27,
    siteType: '2',
    openingSiteId: options.siteId,
    originappurl: options.originUrl
  });
};

export const reportDesktopView = () => {
  bi.log({
    evid: 28,
    openingSiteID: options.siteId,
    siteType: '2',
    originappurl: options.originUrl
  });
};

export const reportEditorClick = (esi, origin) => {
  bi.log({
    openingSiteId: options.siteId,
    originappurl: options.originUrl,
    siteType: '2',
    esi,
    galleryDocIndex: options.galleryDocIndex,
    origin,
    book: options.bookName,
    evid: 15,
    template_type: options.isAdi ? 'ADI' : 'Editor',
  });
};

export const reportInfoClick = () => {
  bi.log({
    evid: 19,
    siteType: '2',
    openingSiteId: options.siteId,
    originappurl: options.originUrl,
  });
};

export const reportSeeAllTemplatesClick = (fromCategory, toCategory) => {
  bi.log({
    evid: 46,
    originappurl: options.originUrl,
    template_id: options.siteId,
    to_category: toCategory,
    from_category: fromCategory,
  });
};
