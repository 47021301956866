export const VIEW_MODE = {
  DESKTOP: 'desktop',
  MOBILE: 'mobile'
};

export const LOADING_MODE = {
  START: 'start',
  COMPLETE: 'complete'
};

export const DEVICE = {
  DESKTOP: 'desktop',
  MOBILE: 'mobile'
};
