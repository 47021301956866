import {combineReducers} from 'redux';
import {LOADING, START, COMPLETE} from './reducers-const';

function active(state = false, action) {
  switch (action.type) {
    case `${LOADING}${START}`:
      return true;
    case `${LOADING}${COMPLETE}`:
      return false;
    default:
      return state;
  }
}

export default combineReducers({
  active
});
