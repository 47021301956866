import URL from 'url';

function getUrlParameters(url) {
  return url.substring(url.indexOf('?') + 1, url.length).split('&');
}

function getParameterValue(parameters, id) {
  let value;
  parameters.forEach(singleParameter => {
    const query = singleParameter.split('=');
    if (query[0] === id) {
      value = query[1];
    }
  });

  return value;
}

function extractEditorUrlFromAppsInstallerUrl(url) {
  if (url.indexOf('https://www.wix.com/website/features') === 0) {
    const parsed = URL.parse(url, true);
    const redirect = decodeURIComponent(parsed.query.redirectUrl);
    return redirect;
  }
  return url;
}

export const getEsi = url => {
  const editorUrl = extractEditorUrlFromAppsInstallerUrl(url);
  return getParameterValue(getUrlParameters(editorUrl), 'editorSessionId');
};

export const getMetaSiteId = url => {
  const editorUrl = extractEditorUrlFromAppsInstallerUrl(url);
  return getParameterValue(getUrlParameters(editorUrl), 'metaSiteId');
};
