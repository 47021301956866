import {combineReducers} from 'redux';

function url(state = '') {
  return state;
}

function description(state = {}) {
  return state;
}

function price(state = '') {
  return state;
}

function title(state = '') {
  return state;
}

function docUrl(state = '') {
  return state;
}

function editorUrl(state = '') {
  return state;
}

function goodFor(state = '') {
  return state;
}

function siteId(state = '') {
  return state;
}

function image(state = '') {
  return state;
}

function id(state = '') {
  return state;
}

function lng(state = '') {
  return state;
}

function metaSiteId(state = '') {
  return state;
}

function editorSessionId(state = '') {
  return state;
}

export default combineReducers({
  url,
  price,
  description,
  title,
  docUrl,
  editorUrl,
  goodFor,
  siteId,
  image,
  id,
  lng,
  metaSiteId,
  editorSessionId
});
