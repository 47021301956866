import {combineReducers} from 'redux';
import view from './view';
import loading from './loading';
import template from './template';
import info from './info';
import experiments from './experiments';
import originUrl from './originUrl';
import config from './config';

export default combineReducers({
  view,
  loading,
  template,
  info,
  experiments,
  originUrl,
  config,
});
