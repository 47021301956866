import React, {Component} from 'react';
import PropTypes from 'prop-types';
import s from './infoButton.scss';
import {translate} from 'react-i18next';

class InfoButton extends Component {
  render() {
    const {t, toggleInfoMode, show, hasBackButton} = this.props;

    return (
      <a
        data-hooks="info-view"
        className={`${s.infoIcon} ${show && !hasBackButton ? s.selected : ''}`}
        tabIndex="0"
        onClick={() => toggleInfoMode(!show)}
        onKeyPress={e => e.charCode === 13 && toggleInfoMode(!show)}
        role="dialog"
        href="#"
      >
        {show && hasBackButton ? t('template.viewer.back') : t('template.viewer.read.more')}
      </a>
    );
  }
}

InfoButton.propTypes = {
  t: PropTypes.func,
  toggleInfoMode: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  hasBackButton: PropTypes.bool
};

export default translate()(InfoButton);
