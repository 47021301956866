export default ({editorSessionId, siteId, metaSiteId, dealerCmsTranslationsUrl, lang, dealerLightboxUrl}) => {
  if (window.wixDealerLightbox) {
    new window.wixDealerLightbox.DealerLightboxApi().initLightbox({
      location: 'TEMPLATE_VIEWER',
      translationsUrl: dealerCmsTranslationsUrl,
      locale: lang,
      viewerUrl: dealerLightboxUrl,
      dealerOptions: {
        editorSessionId,
        siteId,
        metaSiteId
      },
    });
  }
};
