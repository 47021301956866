export const init = (editorSessionId, siteId, metaSiteId) => {
  window.dealerLoaderApi = new window.DealerLoaderApi();
  window.dealerLoaderApi.loadDealer({
    bannerLocation: 'TEMPLATE_VIEWER',
    dealerOptions: {
      editorSessionId,
      siteId,
      metaSiteId
    }
  });
};
