import {combineReducers} from 'redux';
import {VIEW_MODE} from '../utils/helpers';
import {TOGGLE_VIEW, MODE} from './reducers-const';

function selected(state = VIEW_MODE.DESKTOP, action) {
  switch (action.type) {
    case `${TOGGLE_VIEW}${MODE}`:
      return action.view;
    default:
      return state;
  }
}

export default combineReducers({
  selected
});
