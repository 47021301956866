import {combineReducers} from 'redux';
import {FETCH_EXPERIMENTS, _SUCCESS} from './reducers-const';

function active(state = [], action) {
  switch (action.type) {
    case `${FETCH_EXPERIMENTS}${_SUCCESS}`:
      return action.data;
    default:
      return state;
  }
}

export default combineReducers({
  active
});
