import React, {Component} from 'react';
import s from './logo.scss';

class Logo extends Component {

  render() {
    return (
      <a data-hooks="logo" href="https://www.wix.com" className={`${s.logoIcon} ${s.logo}`}><span className={s.hide}>wix.com</span></a>
    );
  }
}

export default Logo;
