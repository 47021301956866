import React, {Component} from 'react';
import PropTypes from 'prop-types';
import s from './mobileApp.scss';
import InfoButton from '../../containers/InfoButton';
import Logo from '../../components/partials/Logo';
import InfoPopUp from '../../containers/InfoPopUp/InfoPopUp';
import * as BIManager from '../../utils/bi';

class MobileApp extends Component {
  componentDidMount() {
    BIManager.reportLoadSuccess();
  }

  render() {
    const {url, showInfoPopUp} = this.props;
    let iframeClasses = s.iframe;
    if (showInfoPopUp) {
      iframeClasses += ' ' + s.iframeHidden;
    }
    return (
      <div data-hooks="app-component" className={s.root}>
        <div data-hooks="mobile-tool-bar-component" className={s.toolBar}>
          <Logo/>
          <InfoButton hasBackButton/>
        </div>
        <iframe
          data-hooks="mobile-iframe"
          src={`${url}?showMobileView=true`}
          width="100%"
          height="100%"
          className={iframeClasses}
        />
        <InfoPopUp isMobile/>
      </div>
    );
  }
}

MobileApp.propTypes = {
  url: PropTypes.string.isRequired,
  showInfoPopUp: PropTypes.bool.isRequired
};

export default MobileApp;
