import React, {Component} from 'react';
import PropTypes from 'prop-types';
import s from './desktopView.scss';

class DesktopView extends Component {

  render() {
    const {url} = this.props;
    return (
      <div data-hooks="desktop-view-component" className={s.display}>
        <iframe
          data-hooks="desktop-iframe"
          src={url}
          width="100%"
          height="100%"
          className={s.iframe}
        />
      </div>
    );
  }
}

DesktopView.propTypes = {
  url: PropTypes.string.isRequired
};

export default DesktopView;
