import {connect} from 'react-redux';
import TemplateDemo from '../../components/TemplateDemo';

function mapStateToProps(state) {
  return {
    url: state.template.url,
    view: state.view.selected,
    info: state.info
  };
}

export default connect(mapStateToProps)(TemplateDemo);
