export const _SUCCESS = '_SUCCESS';
export const MODE = '_MODE';
export const START = '_START';
export const COMPLETE = '_COMPLETE';

export const FETCH_EXPERIMENTS = 'FETCH_EXPERIMENTS';

export const TOGGLE_INFO = 'TOGGLE_INFO';
export const TOGGLE_VIEW = 'TOGGLE_VIEW';
export const LOADING = 'LOADING';
