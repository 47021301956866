import React, {Component} from 'react';
import s from './App.scss';
import ToolBar from '../../containers/ToolBar/ToolBar';
import TemplateDemo from '../../containers/TemplateDemo/TemplateDemo';
import InfoPopUp from '../../containers/InfoPopUp/InfoPopUp';
import * as BIManager from '../../utils/bi';
import fedopsLogger from '../../utils/fedops-logger';
import * as dealerManager from '../../utils/dealer';
import initNewDealer from '../../utils/newDealer';
import PropTypes from 'prop-types';

class App extends Component {
  componentDidMount() {
    BIManager.reportLoadSuccess();
    fedopsLogger.appLoaded();

    const {lang, dealerCmsTranslationsUrl, dealerLightboxUrl, experiments} = this.props;
    const {editorSessionId, siteId, metaSiteId} = this.props.template;

    if (experiments.includes('TemplateViewerUseNewLightboxDealer')) {
      initNewDealer({
        editorSessionId,
        metaSiteId,
        siteId,
        lang,
        dealerCmsTranslationsUrl,
        dealerLightboxUrl
      });
    } else {
      dealerManager.init(editorSessionId, siteId, metaSiteId);
    }
  }

  componentDidUpdate() {
    document.getElementsByTagName('body')[0].classList.toggle('popup', this.props.popUpView);
  }

  render() {
    return (
      <div data-hooks="app-component" className={s.root}>
        <ToolBar/>
        <TemplateDemo/>
        <InfoPopUp/>
      </div>
    );
  }
}

App.propTypes = {
  template: PropTypes.object.isRequired,
  popUpView: PropTypes.bool,
  lang: PropTypes.string,
  dealerCmsTranslationsUrl: PropTypes.string,
  dealerLightboxUrl: PropTypes.string,
  experiments: PropTypes.array,
};

export default App;
