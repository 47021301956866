import {connect} from 'react-redux';
import App from '../../components/App';

function mapStateToProps(state) {
  return {
    template: state.template,
    popUpView: state.info.show,
    lang: state.config.lang,
    dealerCmsTranslationsUrl: state.config.dealerCmsTranslationsUrl,
    dealerLightboxUrl: state.config.dealerLightboxUrl,
    experiments: state.experiments.active,
  };
}

export default connect(mapStateToProps)(App);
