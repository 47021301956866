import {connect} from 'react-redux';
import ToolBar from '../../components/ToolBar/ToolBar';
import {toggleViewMode, toggleInfoMode} from '../../actions';
import * as BIManager from '../../utils/bi';
import {VIEW_MODE} from '../../utils/helpers';

function mapStateToProps(state) {
  return {
    info: state.info,
    view: state.view.selected,
    template: state.template,
    experiments: state.experiments.active,
    editorClick: (esi, origin, msid) => BIManager.reportEditorClick(esi, origin, msid),
    seeAllTemplatesClick: ({fromCategory, toCategory}) => BIManager.reportSeeAllTemplatesClick(fromCategory, toCategory),
    originUrl: state.originUrl,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleViewMode: view => {
      view === VIEW_MODE.DESKTOP ? BIManager.reportDesktopView() : BIManager.reportMobileView();
      return dispatch(toggleViewMode(view));
    },
    toggleInfoMode: show => {
      show ? BIManager.reportInfoClick() : '';
      return dispatch(toggleInfoMode(show));
    }
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ToolBar);
