import 'babel-polyfill';
import React from 'react';
import axios from 'axios';
import {Provider} from 'react-redux';
import ReactDOM from 'react-dom';
import {I18nextProvider} from 'react-i18next';
import {wixAxiosConfig} from '@wix/wix-axios-config';
import i18n from './i18n/client-i18n';
import App from './containers/App';
import MobileApp from './containers/MobileApp';
import configureStore from './store/configureStore';
import * as BIManager from './utils/bi';
import {DEVICE} from './utils/helpers';
import {initFullstory} from './utils/fullstory';


const initialI18n = window.__INITIAL_I18N__;
const baseURL = window.__BASEURL__;
const initialState = window.__INITIAL_STATE__;

initFullstory();
wixAxiosConfig(axios, {baseURL});
const store = configureStore(initialState);
const device = window.__DEVICE__;
BIManager.setBIOptions(window.__BI__);



ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n(initialI18n)}>
      {device === DEVICE.DESKTOP ? <App/> : <MobileApp/>}
    </I18nextProvider>
  </Provider>,
  document.getElementById('root')
);
