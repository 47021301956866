import React from 'react';
import PropTypes from 'prop-types';
import s from './mobileView.scss';

function MobileView({url}) {
  return (
    <div data-hooks="mobile-view-component" className={`${s.display}`}>
      <div className={s.phone}>
        <div className={s.speaker}/>
        <div className={`${s.display} ${s.device}`}>
          <iframe data-hooks="mobile-iframe" src={`${url}?showMobileView=true`} width="100%" height="100%"/>
        </div>
      </div>
    </div>
  );
}

MobileView.propTypes = {
  url: PropTypes.string.isRequired
};

export default MobileView;
