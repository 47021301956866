import {connect} from 'react-redux';
import InfoButton from '../../components/partials/InfoButton';
import {toggleInfoMode} from '../../actions';
import * as BIManager from '../../utils/bi';

function mapStateToProps(state) {
  return {
    show: state.info.show,
    info: state.info
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleInfoMode: show => {
      show ? BIManager.reportInfoClick() : '';
      return dispatch(toggleInfoMode(show));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InfoButton);
