import {connect} from 'react-redux';
import InfoPopUp from '../../components/InfoPopUp/InfoPopUp';
import {toggleInfoMode} from '../../actions';
import * as BIManager from '../../utils/bi';

function mapStateToProps(state) {
  return {
    template: state.template,
    show: state.info.show,
    experiments: state.experiments.active,
    editorClick: (esi, origin, msid) => BIManager.reportEditorClick(esi, origin, msid)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleInfoMode: show => dispatch(toggleInfoMode(show))
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(InfoPopUp);
